<template>
    <div class="mod-config">
        <div style="width: 97%;height:100%;margin: auto;background: white;box-sizing: border-box;padding: 22px;">
            <div class="sousuo" style="height: 41px;line-height: 41px; box-sizing: content-box;margin-bottom: 20px;">
                <span style="color: #333333;font-size: 16px;margin-right: 13px">基地名称</span>
                <el-input type="text" style="width: 200px;height: 40px"  v-model="name"></el-input>

                <!--                <el-select style="background-color: #E5E5E5;border: 1px solid #E5E5E5; border-radius: 4px;" v-model="value" filterable collapse-tags :clearable="true"  placeholder="全部">-->
<!--                    <el-option-->
<!--                            v-for="item in jidiName"-->
<!--                            :key="item.id"-->
<!--                            :label="item.name"-->
<!--                            :value="item.name"/>-->

<!--                </el-select>-->
                <el-button @click="chaxun" style="margin-left: 25px; background: linear-gradient(0deg, #1ECC68, #14B651, #0E902F);border: 1px solid rgba(114,114,114,0.22);
                    border-radius: 4px; color: white;font-size: 16px; width: 100px; height: 40px;">
                    查询
                </el-button>
                <el-button @click="chognzhi" style="margin-left: 25px; background: linear-gradient(0deg, #1ECC68, #14B651, #0E902F);border: 1px solid rgba(114,114,114,0.22);
                    border-radius: 4px; color: white;font-size: 16px; width: 100px; height: 40px;">
                    重置
                </el-button>
            </div>
            <el-table
                    :data="waringList"
                    border
                    :row-class-name="tableRowClassName"
                     >
                <el-table-column width="100"
                        type="index"
                        label="序号"
                >
                </el-table-column>
                <el-table-column
                        width="400"
                        prop="name"
                        label="基地名称"
                >
                </el-table-column>

                <el-table-column
                        prop="provinceName,cityName,districtName,townName"
                        label="基地归属地">
                    <template slot-scope="scope">
                        {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.townName}}
                    </template>
                </el-table-column>
                <el-table-column
                        width="500"
                        prop="address"
                        label="基地地址">
                </el-table-column>
                <el-table-column width="150"
                        prop="greenhouseNum"
                        label="大棚数量">
                </el-table-column>

                <el-table-column
                        width="150"
                        prop="date"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button class="xiangqing"
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)
                                ">详情
                        </el-button>
                    </template>
                </el-table-column>


            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>


    </div>
</template>


<script>
    export default {
        name: "qiheHistory",

        components: {},
        data() {
            return {
                name:'',
                tableData: [],
                dialogVisible: false,
                value1: '',
                waringList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                changeList: [],
                value:[],
                jidiName:[]
            }
        },
        mounted() {
            this.getDataNameList();
            this.getDataList();
            this.$bas.$emit("suiji",'/qiheHistory')

        },
        methods: {
            handleEdit(e,a){
                console.log('接收',e,a)
                const id =a.id;
                const name=a.name;
                console.log("id="+id+"name="+name)
                this.$router.push({ name:"qiheHistoryDetails", query:{id,name}})
            },
            headerStyleEvent({ rowIndex }){
                if (rowIndex == 0||rowIndex == 1){
                    return 'background:linear-gradient(0deg, #E6E6E6, #FFFFFF) !important;height:50px;'
                }else {
                    return 'background:#ffffff !important;height:50px;'
                }
            },
          chaxun(){
            this.pageIndex=1
            this.getDataList()
          },
            //重置
            chognzhi(){
                this.name=''
                this.getDataList()

            },
            getDataNameList(){
                this.$api.get('base/list', {}, result => {
                    this.jidiName = result.rows
                })
            },
            getDataList(){
                this.$api.get('base/list', {pageNum:this.pageIndex,pageSize:this.pageSize,name:this.name}, result => {
                    this.waringList = result.rows
                    this.totalPage = result.total
                })
            },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 == 1) {
                    return 'warning-row';
                } else if (rowIndex === 3) {
                    return 'success-row';
                }
                return '';
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },

        }
    }
</script>

<style scoped>
    .xiangqing{
        width: 86px;
        height: 33px;
        background: url("../../assets/basegreenhouse/btn_green_small.png") no-repeat;
        border: none;
    }
    .xiangqing:hover{
        color: #166D66;
    }
    /deep/.el-table-column{
        background-color: #f6f6f6 !important;
    }
    /deep/ .el-table tbody tr:hover > td {
        background-color: #fdfdfd !important;
    }
    /deep/.el-table th {
         padding: 0;
         margin: 0;
         height: 50px;
         background-color: #f6f6f6 !important;
     }

    .tabLst-title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1A99F2;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .warning-box {
        background: #FFF000;
        margin-top: 20px;
        padding: 30px 36px;
        min-height: 85vh;
    }
    .mod-config{
        width: auto;
        bottom: 20px;
        right: 0px;
        left:0px;
        top: 93px;
        position: absolute;
        margin-left: 240px;
        box-sizing: border-box;
    }

    .el-table{
        box-sizing: content-box;

    }
    /deep/.sousuo .el-select{
        background: none !important;
        border: none !important;
    }

    /*.el-input.el-input--suffix {*/
    /*// 修改背景颜色、字体颜色、边框、宽高*/
    ::v-deep.sousuo .el-select .el-input__inner {
        background: #F1F7F5 !important;
        border: 1px solid #E5E5E5;
        color: #333333;
        height: 41px;
        width: 268px;
       }

    /deep/
    .sousuo .el-select .el-input.is-focus .el-input__inner{
        border: 1px solid #166D66 !important;
    }

    /deep/.sousuo.el-select .el-select .el-input__inner:focus{
        border: 1px solid #166D66 !important;
    }
    /deep/.el-pager li.active{
        color: #009E4F !important;
    }
    .el-pagination{
        margin-top: 36px;
        text-align: center;
    }
</style>
